<template>
  <div>
		<br/>
		<h1 class="main-title">Кол-во сегментов и элементов всего</h1>
		<p>Обновление данных происходит в 9:00 и 18:00 по Москве</p>
		<br/>
		<div class="table-header">
			<v-select-project
				v-model="filters.project"
				class="narrow autocomplete-select"
				@input="updateData"
			/>
		</div>
		<div
			v-for="table in tables"
			:key="table.id"
		>
			<table-container
				:id="table.id"
				:showFilters="false"
			>
				<template v-slot:header>
					{{table.entity === 'segment_count'
						? 'Сегменты'
						: table.entity === 'query_count'
							? 'Запросы'
							: table.entity === 'url_count'
								? 'Страницы'
								: null}}
				</template>
				<v-data-table
					:headers="headers"
					:items="transformedSegments"
					:loading="segmentsAvailabilityLoading"
					hide-default-footer
					dense
				>
				<template v-slot:item.type="{ item }">
					<div>
						<strong>{{item.type === 'category' ? 'Категорий' : item.type === 'group' ? 'Групп страниц' : item.type === 'query_group' ? 'Групп запросов' : null}}</strong>
					</div>
				</template>
				<template v-slot:item.count_main="{ item }">
					<div>
						<span>{{item.count_main[table.entity] ? item.count_main[table.entity].toLocaleString() : '0' }}</span>
					</div>
				</template>
				<template v-slot:item.count_ch="{ item }">
					<div>
						<span>{{item.count_ch[table.entity] ? item.count_ch[table.entity].toLocaleString() : '0'}}</span>
					</div>
				</template>

				<template v-slot:item.diff="{ item }">
					<div>
						<span>{{calcDiff(item, table.entity)}}</span> <strong :class="[calcDiffPrc(item, table.entity) !== 0 && 'danger']">({{calcDiffPrc(item, table.entity)}}%)</strong>
					</div>
				</template>
				</v-data-table>
			</table-container>
			<br />
		</div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TableContainer from '@/components/data/segments/tableContainer.vue'
import selectProject from '@/components/selectProject'

export default {
	components: {
		TableContainer,
		'v-select-project': selectProject
	},
	data () {
		return {
			segmentsAvailabilityLoading: true,
			headers: [
				{
					text: 'Сегмент',
					value: 'type',
					sortable: false,
					width: '25%'
				},
				{
					text: 'MAIN',
					value: 'count_main',
					sortable: false,
					width: '25%'
				},
				{
					text: 'CH',
					value: 'count_ch',
					sortable: false,
					width: '25%'
				},
				{
					text: 'Diff',
					value: 'diff',
					sortable: false,
					width: '25%'
				}
			],
			tables: [
				{
					id: 1,
					entity: 'segment_count'
				},
				{
					id: 2,
					entity: 'query_count'
				},
				{
					id: 3,
					entity: 'url_count'
				}
			],
			filters: {
				project: null,
				isActive: null
			},
			savedID: null,
			foundProject: null
		}
	},

	computed: {
		...mapGetters({
			transformedSegments: 'segments/transformedSegments'
		})
	},

	async mounted () {
		await this.setFilters()
		await this.updateData()
	},

	methods: {
		...mapActions({
			getSegmentsCount: 'segments/getSegmentsCount'
		}),

		async updTable (payload) {
			const query = {
				project_ids: payload.project
					? !Array.isArray(payload.project)
						? [payload.project]
						: payload.project
					: undefined,
				active: 1
			}

			try {
				this.segmentsAvailabilityLoading = true
				await this.getSegmentsCount({ ...query })
			} catch (error) {
				this.$notify({ type: 'error', title: error })
			} finally {
				this.segmentsAvailabilityLoading = false
			}
		},

		calcDiff (item, entity) {
			if (item) {
				return item.count_main[entity] - item.count_ch[entity]
			}
			return null
		},
		calcDiffPrc (item, entity) {
			if (item) {
				if (!item.count_main[entity] && !item.count_ch[entity]) {
					return 0
				} else if (!item.count_main[entity] || !item.count_ch[entity]) {
					return !item.count_ch[entity] && item.count_main[entity] !== 0 ? 100 : !item.count_main[entity] && item.count_ch[entity] !== 0 ? -100 : 100
				} else {
					const num = ((item.count_main[entity] - item.count_ch[entity]) * 100) / item.count_main[entity]
					return parseFloat(num.toFixed(3))
				}
			}
			return null
		},
		async updateUrl (query) {
			await this.$router.replace({
				query: {
					...this.$route.query,
					...query
				}
			}).catch((_) => {})
		},
		async setFilters () {
			const query = this.$route.query
			const projectID = query.project_id

			if (projectID) {
				this.filters.project = !Array.isArray(projectID) ? +projectID : projectID.map((el) => +el)
			}

			this.filters.isActive = true
		},
		async updateData () {
			await this.updateUrl({
				project_id: this.filters.project
					? this.filters.project
					: undefined
			})
			await this.updTable({
				project: this.filters.project
					? this.filters.project
					: null,
				active: 1
			})
		}
	}
}
</script>

<style lang="scss" scoped>
  .table-title {
    font-weight: 400;
    font-size: 28px;
  }

	.danger {
		color: red;
	}
</style>
