<template>
  <div>
    <br/>
    <h2 class="table-container__title padding-left">
      <slot name="header"></slot>
    </h2>
    <div class="table-header padding-left" v-if="showFilters">
		<v-select-project
			class="narrow autocomplete-select"
			v-model="filters.project"
			multiple
		/>
			<v-autocomplete
				v-if="showSegmentFilter"
        class="narrow autocomplete-select"
        v-model="segmentType"
        :items="segmentTypes"
        outlined
        dense
        hide-details
        light
        clearable
        label="Выберите тип сегмента"
      >
      </v-autocomplete>
			<v-autocomplete
				v-if="showSegmentSearchFilter"
        class="narrow autocomplete-select"
        v-model="foundSegment"
        :items="segmentSearchList"
        outlined
        dense
        hide-details
        light
        clearable
        label="Выберите сегмент"
        :filter="filterSegmentFunction"
      >
				<template v-slot:item="{ item }">
          <span><sup>{{item.value}}</sup> {{item.text}}</span>
        </template>
      </v-autocomplete>
      <v-checkbox v-if="showProblematicFilter" v-model="filters.isProblematic" :label="'Только проблемные проекты'"></v-checkbox>
    </div>
		<div class="padding-left total">
			<slot name="total"></slot>
		</div>
    <slot></slot>
  </div>
</template>

<script>
import selectProject from '@/components/selectProject'

export default {
	components: {
		'v-select-project': selectProject
	},
	props: {
		headers: {
			type: Array,
			default: () => ([])
		},
		segmentSearchList: {
			type: Array,
			default: () => ([])
		},
		loading: {
			type: Boolean,
			default: () => { return false }
		},
		id: {
			type: Number
		},
		showProblematicFilter: {
			type: Boolean,
			default: () => { return false }
		},
		showSegmentFilter: {
			type: Boolean,
			default: () => { return false }
		},
		showSegmentSearchFilter: {
			type: Boolean,
			default: () => { return false }
		},
		showFilters: {
			type: Boolean,
			default: () => { return true }
		}
	},

	data () {
		return {
			filters: {
				project: null,
				isProblematic: undefined
			},
			segmentType: undefined,
			segmentTypes: [
				{
					text: 'Категории',
					value: 'category'
				},
				{
					text: 'Группы запросов',
					value: 'query_group'
				},
				{
					text: 'Группы страниц',
					value: 'group'
				}
			],
			foundSegment: null
		}
	},

	async mounted () {
		await this.setFilters()
	},

	watch: {
		filters: {
			immediate: false,
			deep: true,
			async handler (val) {
				this.$emit('updTable', {
					project: val.project
						? val.project
						: null,
					isProblematic: val.isProblematic,
					table_id: this.id
				})

				await this.updateUrl({
					project_id: val.project
						? val.project
						: undefined,
					only_diff: val.isProblematic === true ? 1 : 0
				})
			}
		},
		segmentType: {
			immediate: false,
			async handler (val) {
				this.$emit('updSegmentType', {
					segmentType: val !== null ? val : undefined
				})

				await this.updateUrl({
					segment_type: val !== null ? val : undefined
				})
			}
		},
		foundSegment: {
			async handler (val) {
				this.$emit('updSegmentID', {
					segment_id: val !== null ? val : undefined
				})

				await this.updateUrl({
					segment_id: val !== null ? val : undefined
				})
			}
		}
	},

	methods: {
		filterSegmentFunction (item, queryText, itemText) {
			return itemText.toLocaleLowerCase().indexOf(queryText.trim().toLocaleLowerCase()) > -1 || item.value.toString().indexOf(queryText.trim().toString()) > -1
		},
		async updateUrl (query) {
			await this.$router.replace({
				query: {
					...this.$route.query,
					...query
				}
			}).catch((_) => {})
		},
		async setFilters () {
			const query = this.$route.query
			const projectID = query.project_id
			const showProblematic = query.only_diff
			const segmentType = query.segment_type
			const segmentID = query.segment_id

			if (projectID) {
				this.filters.project = !Array.isArray(projectID) ? +projectID : projectID.map((el) => +el)
			}

			if (showProblematic) {
				this.filters.isProblematic = Boolean(+showProblematic)
			} else {
				this.filters.isProblematic = true
			}

			if (segmentType) {
				this.segmentType = segmentType
			}
			if (segmentID) {
				this.foundSegment = +segmentID
			}
		}
	}
}
</script>

<style lang="scss">
.table-title {
  font-weight: 400;
  font-size: 28px;
}
.narrow {
  max-width: 300px;
  font-size: 12px;
}
.table-header {
  display: flex;
  align-items: center;

  .autocomplete-select {
    margin-right: 10px;
  }

	.v-input--checkbox {
		&:not(:last-child) {
			margin-right: 10px;
		}
	}
}

.table-container__title {
	font-weight: 300;
}
.padding-left {
	padding-left: 15px;
}

.total {
	margin-bottom: 5px;

	p {
		margin-bottom: 0;
	}
}
</style>
